import * as Yup from "yup";

const phoneRegExp = /^\d{3}-?\d{3}-?\d{4}$/;
const phoneExtensionRegExp = /^\d+$/;

export const FIELD_ENUM = {
  firstName: "firstName",
  lastName: "lastName",
  phoneNumber: "phoneNumber",
  phoneExtension: "phoneExtension",
};

export const validation = {
  [FIELD_ENUM.firstName]: Yup.string().trim().required("error_first_name"),
  [FIELD_ENUM.lastName]: Yup.string().trim().required("error_last_name"),
  [FIELD_ENUM.phoneNumber]: Yup.string()
    .trim()
    .min(10, "error_valid_phone")
    .matches(phoneRegExp, "error_valid_phone"),
  [FIELD_ENUM.phoneExtension]: Yup.string()
    .trim()
    .matches(phoneExtensionRegExp, "error_ext"),
};
