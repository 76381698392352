import React, { useState } from "react";

import authApi from "./auth-api";
import { userActions, apiList, methodType, clinetIds } from "./constant";
import { env } from "../cfg/env";

const AppLaunchContext = React.createContext();

const getData = async ({
  body = {},
  url = "",
  method = methodType.post,
  contentType,
  isWithCredentials = false,
}) => {
  let response = await authApi({
    body,
    method,
    url,
    contentType,
    isWithCredentials,
  });
  return response;
};

function AppLaunchProvider({ children }) {
  const [appLaunchState, setAppLaunchState] = useState({
    isError: false,
    isLoading: false,
    isRedirected: false,
  });

  const updateAppLaunchState = async (payload) => {
    const { type = "" } = payload;
    const appEnv = (env?.appEnv ?? "").toString().toLowerCase();
    const appRedirect = encodeURIComponent(env.sso.authClientRedirect ?? "");
    const client_id = clinetIds["mogo"][appEnv];
    const code_challenge = "4Y-TkN3wGIruLHPrtUjvn8pYRys80BlLdgTiipyNmhs";
    const url = apiList[type];
    switch (type) {
      case userActions.authorizeClient: {
        const queryParameters = `?client_id=${client_id}&response_type=code&redirect_uri=${appRedirect}&response_mode=query&state=MOGO&scope=offline_access ${client_id} openid&code_challenge=${code_challenge}&code_challenge_method=plain&nonce=defaultNonce`;
        window.open(url + queryParameters, "_self");
        break;
      }
      case userActions.authToken: {
        setAppLaunchState({ ...appLaunchState, isLoading: true });
        const { body } = payload;
        const { code = "", redirectUrl = null, lang = "en" } = body;
        const data = {
          method: "POST",
          contentType: "application/x-www-form-urlencoded",
          body: `code=${code}&grant_type=authorization_code&scope=offline_access%20${client_id}%20openid&client_id=${client_id}&redirect_uri=${appRedirect}&code_verifier=${code_challenge}`,
          url: url,
        };
        const resp = await getData(data);
        setAppLaunchState({ ...appLaunchState, isLoading: false });

        // incase cors issue, resp is undefined
        if (!resp) {
          setAppLaunchState({ ...appLaunchState, isError: true });
        }

        const { status = null, data: respData = {} } = resp || {};
        if (status === 200) {
          setAppLaunchState({ ...appLaunchState, isLoading: false });
          const { id_token = "" } = respData;
          if (id_token !== "" && redirectUrl !== null) {
            // TODO:[id:session] fix session management
            // window.sessionStorage.setItem("activeSession", true);
            // window.localStorage.setItem("activeApp", "mogo");
            setAppLaunchState({ ...appLaunchState, isRedirected: true });
            window.open(
              `${redirectUrl}?lang=${lang}&token=${id_token}`,
              "_blank"
            );
          }
        } else {
          setAppLaunchState({ ...appLaunchState, isError: true });
        }

        break;
      }
      default:
        break;
    }
  };

  const updateAppLaunchStateLocal = (obj = {}) =>
    setAppLaunchState((prevAppState) => ({ ...prevAppState, ...obj }));

  const value = {
    updateAppLaunchState,
    appLaunchState,
    updateAppLaunchStateLocal,
  };
  return (
    <AppLaunchContext.Provider value={value}>
      {children}
    </AppLaunchContext.Provider>
  );
}

function useAppLaunchProvider() {
  const context = React.useContext(AppLaunchContext);
  if (context === undefined) {
    throw new Error("appContext must be used within a AppLaunchProvider");
  }
  return context;
}

export { AppLaunchProvider, AppLaunchContext, useAppLaunchProvider };
