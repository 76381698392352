import {
  InteractionStatus,
} from "@azure/msal-browser";
import { env } from "../cfg/env";

const getToken = async (
  instance,
  inProgress = "",
  accounts = [],
  setError = () => null
) => {
  if (inProgress === InteractionStatus.None) {
    const accessTokenRequest = {
      scopes: [`https://${env.sso.b2cTenantDomain}.onmicrosoft.com/sso/auth`],
      account: accounts[0],
    };
    return instance
      ?.acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        const idToken = accessTokenResponse.idToken ?? "";
        return idToken;
      })
      .catch((error) => {
        // removing logic for acquiring token using popup
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance
        //     ?.acquireTokenPopup(accessTokenRequest)
        //     .then(function (accessTokenResponse) {
        //       // Acquire token interactive success
        //       let accessToken = accessTokenResponse.idToken;
        //       return accessToken;
        //     })
        //     .catch(function (e) {
        //       // Acquire token interactive failure
        //       console.log(e);
        //       setError(true);
        //     });
        // }
        setError(true);
        console.log(error);
        return null;
      });
  }
  return null;
};

export default getToken;
