const {
  REACT_APP_ENV,
  REACT_APP_SSO_B2C_TENANT,
  REACT_APP_SSO_APP_CLIENT_ID,
  REACT_APP_SSO_POLICY_SIGNIN,
  REACT_APP_SSO_POLICY_FORGOT_PSWD,
  REACT_APP_SSO_POLICY_EDIT_PROFILE,
  REACT_APP_SSO_WEB_APP,
  REACT_APP_SSO_WEB_STORAGE,
  REACT_APP_SSO_SET_SECURITY_QUESTIONS,
  REACT_APP_SSO_MDLITEUSER_REDIRECT,
  REACT_APP_SSO_B2C_TENANTDOMAIN,
  REACT_AUTH_CLIENT_REDIRECT,
  REACT_AUTH_CLIENT_DOMAIN
} = process.env;

export const env = {
  appEnv: REACT_APP_ENV,
  sso: {
    b2cTenantUrl: REACT_APP_SSO_B2C_TENANT,
    b2cTenantDomain: REACT_APP_SSO_B2C_TENANTDOMAIN,
    appClientId: REACT_APP_SSO_APP_CLIENT_ID,
    webApp: REACT_APP_SSO_WEB_APP,
    webStorage: REACT_APP_SSO_WEB_STORAGE,
    authClientRedirect: REACT_AUTH_CLIENT_REDIRECT,
    authClientDomain: REACT_AUTH_CLIENT_DOMAIN,
    policy: {
      signIn: REACT_APP_SSO_POLICY_SIGNIN,
      forgotPassword: REACT_APP_SSO_POLICY_FORGOT_PSWD,
      editProfile: REACT_APP_SSO_POLICY_EDIT_PROFILE,
      setSecurityQuestion: REACT_APP_SSO_SET_SECURITY_QUESTIONS,
    },
    mdliteUser: {
      redirect: REACT_APP_SSO_MDLITEUSER_REDIRECT,
    },
  },
};

// TODO: change pipelines? for proper env vars injections into react app / containers
// TEMP SOLUTION to power both DEV and QA using the same sources
let host = window.location.host;
const lang = window.localStorage.getItem("lang") ?? "en";
if (/mss-02-dev/.test(host) || /login-dev/.test(host)) {
  env.appEnv = "DEV";
  env.sso.b2cTenantUrl = "mss-dev.moneris.com";
  env.sso.b2cTenantDomain = "moneris365devsso";
  env.sso.appClientId = "e634b45d-d178-4c74-b10b-4d94af6a7b70";
  env.sso.policy.signIn = "B2C_1A_SIGNUP_SIGNIN";
  env.sso.policy.forgotPassword = "B2C_1A_PasswordReset";
  env.sso.policy.editProfile = "B2C_1A_PROFILEEDIT";
  env.sso.webApp = "login-dev.moneris.com";
  // env.sso.webStorage = "moneris365devstmssdvcc";
  env.sso.functionDomain = "moneris365dev-func-mss-02-dv-cc.azurewebsites.net";
  env.sso.functionKey =
    "xcCjhXMny1bDekxlS2oi3DOpS_TCan1vPSqdiZ2LW1EjAzFuLN9R1A==";
  env.sso.policy.setSecurityQuestion = "B2C_1A_SecurityQuestions_DE";
  env.sso.mdliteUser.redirect =
    "https://www1-dev.corp.moneris.com/{lang}/onboarding/account/setup";
  env.sso.authClientRedirect = `https://login-dev.moneris.com/${lang}/launch`;
  env.sso.authClientDomain = 'mss-dev.moneris.com/moneris365devsso.onmicrosoft.com';
} else if (
  /-qa-cc/.test(host) ||
  /-qa-ce/.test(host) ||
  /login-qa/.test(host)
) {
  env.appEnv = "QA";
  env.sso.b2cTenantUrl = "mss-qa.moneris.com";
  env.sso.b2cTenantDomain = "moneris365mssqa";
  env.sso.appClientId = "e89a258a-da52-43b6-8848-750abeaa22b7";
  env.sso.policy.signIn = "B2C_1A_SIGNUP_SIGNIN";
  env.sso.policy.forgotPassword = "B2C_1A_PasswordReset";
  env.sso.policy.editProfile = "B2C_1A_PROFILEEDIT";
  env.sso.webApp = "login-qa.moneris.com";
  // env.sso.webStorage = "moneris365devstmssdvcc";
  env.sso.functionDomain = "login-qa.moneris.com";
  // env.sso.functionDomain = "moneris365-func-mss-02-qa-cc.azurewebsites.net";
  env.sso.functionKey =
    "Y1I9jXK0rRadeNapys4FdMwQxBDEzw4dXl0YRk0cVWqjAzFuileBpQ==";
  env.sso.policy.setSecurityQuestion = "B2C_1A_SecurityQuestions_DE";
  env.sso.mdliteUser.redirect =
    "https://www1-qa.moneris.com/{lang}/onboarding/account/setup";
  env.sso.authClientRedirect = `https://login-qa.moneris.com/${lang}/launch`;
  env.sso.authClientDomain = 'mss-qa.moneris.com/moneris365mssqa.onmicrosoft.com';
} else if (/local/.test(host)) {
  env.appEnv = "QA";
  env.sso.b2cTenantUrl = "mss-qa.moneris.com";
  env.sso.b2cTenantDomain = "moneris365mssqa";
  env.sso.appClientId = "e89a258a-da52-43b6-8848-750abeaa22b7";
  env.sso.policy.signIn = "B2C_1A_SIGNUP_SIGNIN";
  env.sso.policy.forgotPassword = "B2C_1A_PasswordReset";
  env.sso.policy.editProfile = "B2C_1A_PROFILEEDIT";
  env.sso.webApp = "login-qa.moneris.com";
  // env.sso.webStorage = "moneris365devstmssdvcc";
  env.sso.functionDomain = "login-qa.moneris.com";
  // env.sso.functionDomain = "moneris365-func-mss-02-qa-cc.azurewebsites.net";
  env.sso.functionKey =
    "Y1I9jXK0rRadeNapys4FdMwQxBDEzw4dXl0YRk0cVWqjAzFuileBpQ==";
  env.sso.policy.setSecurityQuestion = "B2C_1A_SecurityQuestions_DE";
  env.sso.mdliteUser.redirect =
    "https://www1-qa.moneris.com/{lang}/onboarding/account/setup";
  env.sso.authClientRedirect = `http://localhost:3000/${lang}/launch`;
  env.sso.authClientDomain = 'mss-qa.moneris.com/moneris365mssqa.onmicrosoft.com';
} else if (/-sp-ce/.test(host) || /login/.test(host) || /-sp-cc/.test(host)) {
  env.appEnv = "PROD";
  env.sso.b2cTenantUrl = "mss.moneris.com";
  env.sso.b2cTenantDomain = "monerisportal";
  env.sso.appClientId = "452f9235-f1ff-4a52-82bf-64357c41ca31";
  env.sso.policy.signIn = "B2C_1A_SIGNUP_SIGNIN";
  env.sso.policy.forgotPassword = "B2C_1A_PasswordReset";
  env.sso.policy.editProfile = "B2C_1A_PROFILEEDIT";
  env.sso.webApp = "login.moneris.com";
  // env.sso.webStorage = "moneris365devstmssdvcc";
  env.sso.functionDomain = "login.moneris.com";
  env.sso.functionKey =
    "b8fMceOGEqUOTvDrlVW5AwbfjSYg_mtbguD0B8_7H_gyAzFu4oEjlA==";
  env.sso.policy.setSecurityQuestion = "B2C_1A_SecurityQuestions_DE";
  env.sso.mdliteUser.redirect =
    "https://www2.moneris.com/{lang}/onboarding/account/setup";
  env.sso.authClientRedirect = `https://login.moneris.com/${lang}/launch`;
  env.sso.authClientDomain = 'mss.moneris.com/monerisportal.onmicrosoft.com';
}
