/**
 * Attempt to remove /en|/fr from the front of the string if exists
 * @param {String} url
 * @returns {String}
 */
const removeLanguageFromUrl = (url) => {
  const match = url.match(/(?:\/(?:en|fr))([\w\-/\d?_]+)/i);
  return match?.[1] ?? url;
};

const extractLanguageFromPathname = (url) => {
  const match = url.match(/^\/(en|fr)(?=\/[\w\-/\d]+)?/i);
  return match?.[1]?.toLowerCase();
};

const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export { removeLanguageFromUrl, extractLanguageFromPathname, capitaliseFirstLetter };
