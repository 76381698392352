import React, { useState, useEffect } from "react";
import { PageLayout } from "../../components/Home/PageLayout";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import { useInfoProvider } from "../../contextProviders/InfoProvider";
import { useAppLaunchProvider } from "../../contextProviders/AppLaunchProvider";
import { userActions } from "../../contextProviders/constant";
import EncourageSetup from "../../components/SetupModal/index";
import createTranslator from "../../util/translationHelper";
import Notification from "@moneris-portal/notification";
import messages from "./translations/messages";
import { useHistory } from "react-router-dom";
import Loader from "@moneris-portal/loader";
import getToken from "../../util/tokenGenerator";
import { useMsal } from "@azure/msal-react";
import { env } from "../../cfg/env";

import "./index.scss";

function Home({ lang }) {
  const { instance, inProgress, accounts } = useMsal();
  const history = useHistory();
  const { appState = {}, updateAppStateLocal } = useAppStateProvider();
  const { updateAppLaunchState } = useAppLaunchProvider();
  const { dispatch } = useInfoProvider();
  const {
    apps = [{}],
    profile = {},
    isLoading = false,
    isMd2Lite = false,
  } = appState;
  const { app_code = "" } = apps[0] ?? {};
  // temporary disabling encourgement step
  const isEncourgementStepEnabled =
    !(apps.length === 1 && app_code === "DE") && false;
  const [showStep, handleShowStep] = useState(isEncourgementStepEnabled);
  const [isError, setError] = useState(false);
  const { firstName = "Username" } = profile;
  const { firstSignIn = false } = profile;
  const translate = createTranslator(messages, lang);
  const testVar = window.localStorage?.getItem("testVar") ?? "false";

  useEffect(async () => {
    const { app_code = "", login_endpoint = "" } = apps[0] ?? {};
    if (apps?.length === 1 && app_code === "DE") {
      updateAppStateLocal({ isLoading: true });
      const token = await getToken(instance, inProgress, accounts, setError);
      const endpoint = login_endpoint?.toString()?.split("?")[0] ?? "";
      if (token && endpoint && testVar !== "true")
        window.open(`${endpoint}?token=${token}&sso=1&lang=${lang}`, "_self");
      else {
        updateAppStateLocal({ isLoading: false });
      }
    } else {
      apps.forEach(async (item) => {
        const { app_code = "", login_endpoint } = item;
        if (app_code?.toString().toLowerCase() === "md2") {
          updateAppStateLocal({ isLoading: true });
          const token = await getToken(
            instance,
            inProgress,
            accounts,
            setError
          );
          const endpoint = login_endpoint?.toString()?.split("?")[0] ?? "";
          if (token && endpoint && testVar !== "true")
            window.open(
              `${endpoint
                ?.toString()
                ?.replace("{lang}", lang)}?token=${token}&sso=1`,
              "_self"
            );
          else {
            updateAppStateLocal({ isLoading: false });
          }
        }
      });
    }
  }, [apps]);

  useEffect(async () => {
    let url = env?.sso?.mdliteUser?.redirect ?? "";
    if (isMd2Lite) {
      updateAppStateLocal({ isLoading: true });
      const token = await getToken(instance, inProgress, accounts, setError);
      if (token && url && testVar !== "true")
        window.open(
          `${url?.toString()?.replace("{lang}", lang)}?token=${token}&sso=1`,
          "_self"
        );
      else {
        updateAppStateLocal({ isLoading: false });
      }
    }
  }, [isMd2Lite]);

  //logout user incase application state is not fully authenticated
  useEffect(() => {
    if (isError) instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }, [isError]);

  const handleDoItLater = () => {
    dispatch({
      type: userActions.updateUserInfo,
      body: { firstSignIn: false },
    });
    handleShowStep(false);
  };

  const handleAuditLog = (payload) => {
    dispatch({
      type: userActions.auditLog,
      body: { ...payload },
    });
  };

  return (
    <div id="home_container">
      {isError && (
        <div style={{ maxWidth: "27rem" }}>
          <Notification variant="danger">
            {translate("invalid_message")}
          </Notification>
        </div>
      )}
      {!isLoading && (
        <>
          {firstSignIn && showStep && (
            <EncourageSetup
              history={history}
              handleShowStep={handleDoItLater}
              lang={lang}
            />
          )}
          <PageLayout
            apps={apps}
            username={firstName}
            handleAuditLog={handleAuditLog}
            updateAppLaunchState={(action) => {
              updateAppLaunchState(action);
            }}
          />
        </>
      )}
      {isLoading && <Loader lang={lang} />}
    </div>
  );
}

export default Home;
