import React, { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import authApi from "./auth-api";
import { userActions, apiList, methodType } from "./constant";

const AppStateContext = React.createContext();

const getData = async ({
  body = {},
  url = "",
  method = methodType.post,
  isWithCredentials = true,
}) => {
  let response = await authApi({ body, method, url, isWithCredentials });
  return response;
};

function AppStateProvider({ children }) {
  const [appState, setAppState] = useState({
    isError: false,
  });
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const origin = "settings";

  useEffect(async () => {
    if (isAuthenticated) {
      const myAccounts = instance.getAllAccounts();
      const accountObj = myAccounts[0] ?? {};
      const { idTokenClaims = {} } = accountObj;
      // todo enable after integeration
      const { uid = "", env = "qa", isMigrationFlow = false } = idTokenClaims;
      setAppState({
        ...appState,
        uid: uid,
        env: env,
        isMigrationFlow: isMigrationFlow,
        isLoading: true,
        isError: false,
        isMd2Lite: false,
      });
      if (uid) {
        await getUserData(uid);
      }
    }
  }, [isAuthenticated]);

  const getUserData = async (uid) => {
    //sync existing app lang
    const lang = window.localStorage.getItem("lang");
    await updateAppState({
      body: { preferred_language: lang },
      type: userActions.updateUserInfo,
      uid,
    });
    const url = apiList[userActions.fetchUserInfo];
    const response = await getData({
      body: { user_id: uid, origin },
      method: methodType.post,
      url,
    });

    const { user = "", success = false } = response?.data ?? {};
    if (success) {
      const {
        apps,
        first_name = "",
        last_name = "",
        mobile_phone = "",
        email = "",
        display_name = "",
        first_sign_in: firstSignIn = false,
        mfa = {},
        preferred_language = "en",
        contact_phone = "",
        contact_phone_ext = "",
        password_last_change_date = null,
        password_exp_date = null,
      } = user;
      window.localStorage.setItem(
        "lang",
        String(preferred_language).toLowerCase()
      );
      const isMd2LiteUserResponse = await getData({
        body: { user_id: uid, email: email },
        method: methodType.post,
        url: apiList[userActions.isMd2Lite],
      });

      setAppState({
        ...appState,
        uid: uid,
        isLoading: false,
        preferred_language,
        isMd2Lite: isMd2LiteUserResponse?.data?.success ?? false,
        profile: {
          firstName: first_name,
          lastName: last_name,
          displayName: display_name,
          businessPhone: contact_phone,
          userPhone: mobile_phone,
          userEmail: email,
          businessExtension: contact_phone_ext,
          firstSignIn,
          mfa: mfa,
          passLastChange: password_last_change_date,
          passExpiry: password_exp_date,
        },
        apps,
      });
    } else {
      setAppState({
        ...appState,
        uid: uid,
        isLoading: false,
      });
    }
  };

  const updateAppState = async (payload) => {
    const { type = "", body = {}, uid: uidNew = "" } = payload;
    const { uid = uidNew } = appState;
    let url = "";
    let response = {};
    switch (type) {
      case userActions.fetchUserInfo: {
        url = apiList[userActions.fetchUserInfo];
        response = await getData({
          body: { ...body, user_id: uid, origin },
          method: methodType.post,
          url,
        });
        const { user = "", success = false } = response?.data ?? {};
        if (success) {
          const {
            first_name = "",
            last_name = "",
            mobile_phone = "",
            email = "",
            display_name = "",
            mfa = {},
            preferred_language = "en",
            contact_phone = "",
            contact_phone_ext = "",
            password_last_change_date = null,
            password_exp_date = null,
          } = user;
          window.localStorage.setItem(
            "lang",
            String(preferred_language).toLowerCase()
          );
          setAppState({
            ...appState,
            preferred_language,
            isLoading: false,
            profile: {
              firstName: first_name,
              lastName: last_name,
              displayName: display_name,
              businessPhone: contact_phone,
              userPhone: mobile_phone,
              userEmail: email,
              businessExtension: contact_phone_ext,
              mfa: mfa,
              passLastChange: password_last_change_date,
              passExpiry: password_exp_date,
            },
          });
        } else {
          setAppState({
            ...appState,
            isLoading: false,
          });
        }
        break;
      }
      case userActions.getAppIds: {
        const myAccounts = instance.getAllAccounts() ?? [];
        const accountObj = myAccounts[0] ?? {};
        const { name = "" } = accountObj;
        setAppState({ ...appState, firstName: name });
        break;
      }
      case userActions.updateUserInfo: {
        url = apiList[userActions.updateUserInfo];
        updateAppStateLocal({ ...appState, isLoading: true });
        response = await getData({
          body: { ...body, user_id: uid, origin },
          method: methodType.post,
          url,
        });
        if (response.data.success) {
          console.log("lang synced successful");
        } else {
          console.log("lang sync unsuccessful");
        }

        break;
      }
      case userActions.middlewareLogout: {
        const url = apiList[userActions.middlewareLogout];
        const { email =""} = body;
        response = await getData({
          body: {},
          method: methodType.get,
          url: `${url}?email=${email}`,
          isWithCredentials: false,
        });
        if (response.data.success) {
          console.log("logout successful");
        } else {
          console.log("error logging out user");
        }

        break;
      }
      default:
        break;
    }
  };

  const updateAppStateLocal = (obj = null) =>
    setAppState((prevAppState) => ({ ...prevAppState, ...obj }));

  const value = { appState, updateAppState, updateAppStateLocal, getUserData };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

function useAppStateProvider() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("appContext must be used within a AppStateProvider");
  }
  return context;
}

export { AppStateProvider, AppStateContext, useAppStateProvider };
