const messages = {
  en: {
    your_devices_and_services: 'Your device(s) and service(s)',

    desk_5000: 'Desk/5000',
    learn_how_to_set_up_your_device:
      'Learn how to set up your device for your needs, perform everyday transactions, and more.',
    ict250: 'iCT250',
    ict250_with_ipp320: 'iCT250 with iPP320',
    instalink_ivr: 'Instalink IVR',
    ipp320: 'iPP320',
    iwl220: 'iWL220',
    iwl255: 'iWL255',
    moneris_go: 'Moneris Go',
    moneris_go_plus: 'Moneris Go Plus',
    move_5000: 'Move/5000',
    p400: 'P400',
    payd_pro_plus: 'PAYD Pro Plus',
    payd_pro_plus_with_ipp320: 'PAYD Pro Plus with iPP320',
    ux_301: 'UX 301',
    v400c: 'V400C',
    v400c_with_p400: 'V400C with P400',
    v400m: 'V400m',
    vx_520: 'VX 520',
    vx_820_duet: 'VX 820 Duet',
    '3ds': '3DS',
    learn_how_to_set_up_your_service: 'Learn how to set up your service.',
    gift_card: 'Gift Card',
    kount: 'Kount',
    multi_currency_pricing: 'Multi Currency Pricing',
    vault: 'Vault',

    desk_5000_secondary_link: 'https://www.moneris.com/en/support/setup/desk5000',
    ict250_secondary_link:
      'https://www.moneris.com/help/iCT250_Webhelp2021/ict250_webhelp_index.htm',
    ict250_with_ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/devices/ict250/ict250_v532_ref_guide_eng.ashx',
    instalink_ivr_secondary_link:
      'https://www.moneris.com/-/media/files/devices/instalink-ivr/instalink-ivr-en-ref-guide.ashx',
    ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/downloadable_guides/ipp320_mrc_qig-eng.ashx',
    iwl220_secondary_link:
      'https://www.moneris.com/help/iWL220_Webhelp2021/iWL220_webhelp_index.htm',
    iwl255_secondary_link:
      'https://www.moneris.com/help/iWL255_Webhelp2021/iWL255_webhelp_index.htm',
    moneris_go_secondary_link: 'https://www.moneris.com/en/support/moneris-go/moneris-go',
    moneris_go_plus_secondary_link: 'https://www.moneris.com/en/support/setup/moneris-go-plus',
    move_5000_secondary_link: 'https://www.moneris.com/en/support/setup/move5000',
    p400_secondary_link:
      'https://www.moneris.com/-/media/files/downloadable_guides/p400_mrc_qig-eng.ashx',
    payd_pro_plus_secondary_link: 'https://my.getpayd.com/help/en_CA/moneris_pos_webhelp_home.htm',
    payd_pro_plus_with_ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/devices/payd-pro-plus-mobile/ipp320-qig_ref_en.ashx',
    ux_301_secondary_link:
      'https://www.moneris.com/-/media/files/devices/ux-301/ux-301-quick-reference-guide-v1.ashx',
    v400c_secondary_link: 'https://www.moneris.com/en/support/setup/v400c',
    v400c_with_p400_secondary_link: 'https://www.moneris.com/en/support/setup/v400c',
    v400m_secondary_link: 'https://www.moneris.com/en/support/setup/v400m',
    vx_520_secondary_link: 'https://www.moneris.com/help/520_4.32f_UPWebhelp/520_VCE_Webhelp.htm',
    vx_820_duet_secondary_link:
      'https://www.moneris.com/help/820_4.32f_Webhelp/820_vce_webhelp.htm',
    '3ds_secondary_link': 'https://developer.moneris.com/livedemo/3ds2/about/guide/dotnet',
    gift_card_secondary_link: 'https://www.moneris.com/en/support/products/gift-card-program',
    kount_secondary_link:
      'https://developer.moneris.com/Documentation/NA/E-Commerce%20Solutions/E-Fraud/Kount',
    multi_currency_pricing_secondary_link:
      'https://developer.moneris.com/Documentation/NA/E-Commerce%20Solutions/MCP',
    vault_secondary_link: 'https://www.moneris.com/en/support/devices/moneris-gateway',

    user_guide: 'User guide',
    launch: 'Launch',

    this_portal_enhances_your_experience:
      'This portal enhances your experience and utilization of your Go device:',
    easily_configure_and_manage_your_go_devices:
      'Easily configure & manage your Go device(s) settings, users & permissions',
    email_payment_requests_to_your_customers:
      'Email payment requests to your customers, process & refund transactions',
    realtime_cloud_reporting: 'Realtime cloud reporting',

    this_platform_allows_you_to_securely_manage:
      'This platform allows you to securely manage payments on your website:',
    enables_you_to_accept: 'Enables you to accept and process payments from your website',
    manage_and_configure_users: 'Manage and configure users and permissions',
    customize_payment_pages_leveraging_our_api: 'Customize payment pages leveraging our API',

    this_is_your_business_hub:
      'This is your business hub where you can access resources and tools to manage your business needs:',
    view_monthly_statement_and_reports: 'View monthly statement and reports',
    order_additional_devices: 'Order additional devices',
    manage_chargebacks_and_disputes: 'Manage chargebacks and disputes',

    this_platform_allows_you_configure_your_payd_solution:
      'This platform allows you configure your PAYD solution:',
    manage_inventory_and_generate_reports_to_help_you:
      'Manage inventory and generate reports to help you',
    stay_on_top_of_your_business: 'Stay on top of your business',
    manage_access_settings_for_employees_and_customers:
      'Manage access settings for employees and customers',

    setup_guide: 'Setup guide',
    set_up_now: 'Set up now',
    set_up_your_account_with_the_following_info: 'Set up your account(s) with the following info',
    merchant_id: 'Merchant ID: ',
    store_id: 'Store ID: ',
    go_portal: 'Go Portal',
    moneris_gateway: 'Moneris Gateway',
    merchant_direct: 'Merchant Direct',
    payd_administration: 'PAYD Administration',
    set_up_your_business_command_centre: 'Set up your business command centre',
    your_portals: 'Your portal(s)',
    show_all_acc_info: 'Show all account info',

    go_portal_business_primary_link: 'https://www.monerisgo.com/signup',
    go_portal_business_secondary_link: 'https://www.moneris.com/help/mgoportal_sso-eng/index.htm#t=Getting_started%2Fhomepage.htm',
    moneris_gateway_business_primary_link: 'https://www3.moneris.com/mpg/activate/',
    moneris_gateway_business_secondary_link:
      'https://www.moneris.com/en/support/devices/moneris-gateway',
    payd_administration_business_primary_link:
      'https://my.getpayd.com/ebox/merchant/activate/lang=EN',
    payd_administration_business_secondary_link:
      'https://my.getpayd.com/help/en_CA/moneris_pos_webhelp_home.htm',

    go_portal_portal_primary_link: 'https://www.monerisgo.com/login',
    moneris_gateway_portal_primary_link: 'https://www3.moneris.com/mpg/index.php',
    merchant_direct_portal_secondary_link:
      'https://www.moneris.com/en/support/products/merchant-direct',
    payd_administration_portal_primary_link: 'https://my.getpayd.com/ebox/?a=log&lang=EN',
  },
  fr: {
    your_devices_and_services: 'Vos appareils et services',

    desk_5000: 'Terminal Desk/5000',
    learn_how_to_set_up_your_device:
      'Découvrez comment configurer l’appareil pour répondre à vos besoins, traiter des transactions quotidiennes, et plus encore.',
    ict250: 'Terminal iCT250',
    ict250_with_ipp320: 'Terminal iCT250 avec clavier NIP iPP320',
    instalink_ivr: 'Système de réponse vocale interactive Instalink',
    ipp320: 'Clavier NIP iPP320',
    iwl220: 'Terminal iWL220',
    iwl255: 'Terminal iWL255',
    moneris_go: 'Terminal Moneris Go',
    moneris_go_plus: 'Terminal Moneris Go Plus',
    move_5000: 'Terminal Move/5000',
    p400: 'Clavier NIP P400',
    payd_pro_plus: 'PAYD Pro Plus',
    payd_pro_plus_with_ipp320: 'PAYD Pro Plus avec clavier NIP iPP320',
    ux_301: 'Appareil UX 301',
    v400c: 'Terminal V400c',
    v400c_with_p400: 'Terminal V400c avec clavier NIP P400',
    v400m: 'Terminal V400m',
    vx_520: 'Terminal VX 520',
    vx_820_duet: 'Terminal VX 820 Duet',
    '3ds': 'Solution 3DS',
    learn_how_to_set_up_your_service: 'Découvrez comment configurer cette solution.',
    gift_card: 'Cartes-cadeaux',
    kount: 'Kount',
    multi_currency_pricing: 'Tarification multidevise',
    vault: 'Chambre forte',

    desk_5000_secondary_link: 'https://www.moneris.com/fr-ca/soutien/configuration/desk5000',
    ict250_secondary_link:
      'https://www.moneris.com/help/iCT250_Webhelp2021/ict250_webhelp_index.htm',
    ict250_with_ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/devices/ict250/ict250_v532_ref_guide_frn.ashx',
    instalink_ivr_secondary_link:
      'https://www.moneris.com/-/media/files/devices/instalink-ivr/instalink-ivr-ref-guide-fr.ashx',
    ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/downloadable_guides/ipp320_mrc_qig-frn.ashx',
    iwl220_secondary_link:
      'https://www.moneris.com/help/iWL220_Webhelp2021/iWL220_webhelp_index.htm',
    iwl255_secondary_link:
      'https://www.moneris.com/help/iWL255_Webhelp2021/iWL255_webhelp_index.htm',
    moneris_go_secondary_link: 'https://www.moneris.com/fr-ca/soutien/moneris-go/moneris-go',
    moneris_go_plus_secondary_link:
      'https://www.moneris.com/fr-ca/soutien/configuration/moneris-go-plus',
    move_5000_secondary_link: 'https://www.moneris.com/fr-ca/soutien/configuration/move5000',
    p400_secondary_link:
      'https://www.moneris.com/-/media/files/downloadable_guides/p400_mrc_qig-frn.ashx',
    payd_pro_plus_secondary_link: 'https://my.getpayd.com/help/en_CA/moneris_pos_webhelp_home.htm',
    payd_pro_plus_with_ipp320_secondary_link:
      'https://www.moneris.com/-/media/files/payd-pro-plus/mobile-guides---fr/ipp320-ref-guide-fr.ashx',
    ux_301_secondary_link:
      'https://www.moneris.com/-/media/files/devices/ux-301/ux-301-quick-reference-guide-fr-v1.ashx',
    v400c_secondary_link: 'https://www.moneris.com/fr-ca/soutien/configuration/v400c',
    v400c_with_p400_secondary_link: 'https://www.moneris.com/fr-ca/soutien/configuration/v400c',
    v400m_secondary_link: 'https://www.moneris.com/fr-ca/soutien/configuration/v400m',
    vx_520_secondary_link:
      'https://www.moneris.com/help/FR/FR_520_VCE_Webhelp/welcome_page.htm#t=Structure_Files%2Fwelcome_page.htm',
    vx_820_duet_secondary_link:
      'https://www.moneris.com/help/FR/FR_820_VCE_Webhelp/welcome_page.htm#t=Structure_Files%2Fwelcome_page.htm',
    '3ds_secondary_link': 'https://developer.moneris.com/livedemo/3ds2/about/guide/dotnet',
    gift_card_secondary_link: 'https://www.moneris.com/fr-ca/soutien/produits/cartes-cadeaux',
    kount_secondary_link:
      'https://developer.moneris.com/Documentation/NA/E-Commerce%20Solutions/E-Fraud/Kount',
    multi_currency_pricing_secondary_link:
      'https://developer.moneris.com/Documentation/NA/E-Commerce%20Solutions/MCP',
    vault_secondary_link: 'https://www.moneris.com/fr-ca/soutien/appareils/passerelle-moneris',

    user_guide: 'Guide d’utilisateur',
    launch: 'Lancer',

    this_portal_enhances_your_experience:
      'Ce portail améliore votre expérience et l’utilisation de votre appareil Moneris Go :',
    easily_configure_and_manage_your_go_devices:
      'Configurez et gérez facilement les paramètres, les utilisateurs et les permissions de vos appareils Go',
    email_payment_requests_to_your_customers:
      'Envoyez des demandes de paiement par courriel à vos clients, en plus de traiter des transactions et des remboursements',
    realtime_cloud_reporting: 'Accédez à des rapports infonuagiques en temps réel',

    this_platform_allows_you_to_securely_manage:
      'Cette plateforme vous permet de gérer de façon sécuritaire les paiements de votre site Web :',
    enables_you_to_accept: 'Acceptez et traitez les paiements depuis votre site Web',
    manage_and_configure_users: 'Gérez et configurez les utilisateurs et les permissions',
    customize_payment_pages_leveraging_our_api:
      'Personnalisez vos pages de paiement avec notre API',

    this_is_your_business_hub:
      'Marchand Direct est votre ressource centrale pour gérer les besoins de votre entreprise :',
    view_monthly_statement_and_reports: 'Accédez à des relevés mensuels et à des rapports',
    order_additional_devices: 'Commandez des appareils supplémentaires',
    manage_chargebacks_and_disputes: 'Gérez les débits compensatoires et les différends',

    this_platform_allows_you_configure_your_payd_solution:
      'Cette plateforme vous permet de configurer votre solution PAYD :',
    manage_inventory_and_generate_reports_to_help_you: 'Gérez vos stocks et produisez des rapports',
    stay_on_top_of_your_business: 'Restez au fait des tendances de votre entreprise',
    manage_access_settings_for_employees_and_customers:
      'Gérez les paramètres d’accès de vos employés et de vos clients',

    setup_guide: 'Guide de configuration',
    set_up_now: 'Configurer maintenant',
    set_up_your_account_with_the_following_info:
      'Configurer vos comptes avec les identifiants suivants',
    merchant_id: 'ID de commerçant : ',
    store_id: 'ID de magasin : ',
    go_portal: 'Portail Moneris Go',
    moneris_gateway: 'Passerelle Moneris',
    merchant_direct: 'Marchand Direct',
    payd_administration: 'Administration PAYD',
    set_up_your_business_command_centre: 'Configurer le centre de commande de mon entreprise',
    your_portals: 'Votre portail',
    show_all_acc_info: 'Afficher toutes les informations du compte',

    go_portal_business_primary_link: 'https://www.monerisgo.com/fr/signup',
    go_portal_business_secondary_link:
      'https://www.moneris.com/help/mgoportal_sso-eng/index.htm#t=Getting_started%2Fhomepage.htm',
    moneris_gateway_business_primary_link: 'https://www3.moneris.com/mpg/activate/',
    moneris_gateway_business_secondary_link:
      'https://www.moneris.com/fr-ca/soutien/appareils/passerelle-moneris',
    payd_administration_business_primary_link:
      'https://my.getpayd.com/ebox/merchant/activate/&lang=FR',
    payd_administration_business_secondary_link:
      'https://my.getpayd.com/help/en_CA/moneris_pos_webhelp_home.htm',

    go_portal_portal_primary_link: 'https://www.monerisgo.com/fr/login',
    moneris_gateway_portal_primary_link: 'https://www3.moneris.com/mpg/?chlang=fr',
    merchant_direct_portal_secondary_link:
      'https://www.moneris.com/fr-ca/soutien/produits/marchand-direct',
    payd_administration_portal_primary_link: 'https://my.getpayd.com/ebox/?a=log&lang=FR',
  },
};

export default messages;
