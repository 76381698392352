import React, { useState } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import ApplicationCard from "../Library/ApplicationCard";
import Notification from "@moneris-portal/notification";
import UsefulLinks from "./UsefulLinks/index";
import ResourceCardRow from "./ResourceCardRow/index";
import createTranslator from "../../util/translationHelper";
import messages from "./translations/messages";
import getToken from "../../util/tokenGenerator";

import "./index.scss";

export const PageLayout = ({
  apps = [],
  username,
  handleAuditLog,
  children,
  updateAppLaunchState = () => null,
}) => {
  const { instance, inProgress, accounts } = useMsal();
  const [isError, setError] = useState(false);
  const language = window?.localStorage?.getItem("lang") ?? "en";
  const translate = createTranslator(messages);

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <div id="page_layout">
      {/* <NavigationBar /> */}
      <br />
      <br />
      {children}
      <br />
      <AuthenticatedTemplate>
        <div>
          <h3>
            {`${
              language === "en" ? "Welcome back" : "Content de te revoir"
            }, ${username}!`}
          </h3>
          {isError && (
            <div style={{ maxWidth: "27rem" }}>
              <Notification variant="danger">
                {`${
                  language === "en"
                    ? "Something went wrong. Try again."
                    : "Une erreur c'est produit. Veuillez essayer de nouveau."
                }
                `}
              </Notification>
            </div>
          )}

          <div id="page_layout_body">
            <h4>{language === "en" ? "Your portal(s)" : "Votre portail"}</h4>
            <div className="app_card_grid">
              {apps?.length !== 0 &&
                apps.map((val) => {
                  let { login_endpoint = "", app_code } = val;
                  login_endpoint = login_endpoint.replace("{lang}", "en");
                  return (
                    <ApplicationCard
                      applicationObject={{
                        ...val,
                        login_endpoint,
                      }}
                      getToken={async () =>
                        await getToken(instance, inProgress, accounts, setError)
                      }
                      handleAuditLog={handleAuditLog}
                      isAutoRedirect={
                        app_code?.toString().toLowerCase() === "mogo"
                      }
                      handleAppLaunch={(action) => {
                        updateAppLaunchState(action);
                      }}
                    />
                  );
                })}

              {apps.length === 0 && <h3>No data to display</h3>}
            </div>
          </div>

          <div className="section_row">
            <ResourceCardRow lang={language} />
            <div className="useful_links_block">
              <h3>{translate("useful_links")}</h3>
              <UsefulLinks />
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
    </div>
  );
};
