const applicationDescription = {
    md2:{
        desc:'this_is_your_business_hub',
        list:[
            'view_monthly_statement_and_reports',
            'order_additional_devices',
            'manage_chargebacks_and_disputes',
        ],
        urlCode:'merchant_direct_portal_secondary_link'
       
    },
    de:{
        desc:'this_is_your_business_hub',
        list:[
            'view_monthly_statement_and_reports',
            'order_additional_devices',
            'manage_chargebacks_and_disputes',
        ],
        urlCode:'merchant_direct_portal_secondary_link'
       
    },
    mogo:{
        desc:'this_is_your_business_hub',
        list:[
            'view_monthly_statement_and_reports',
            'order_additional_devices',
            'manage_chargebacks_and_disputes',
        ],
        urlCode:'go_portal_business_secondary_link'    
    }
}


export default applicationDescription;